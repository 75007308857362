<template>
  <div :class="['accordion', { 'is-active': open }]">
    <div class="accordion__header" @click="open = !open">
      <h3 class="accordion__headline headline--md text-bold text-uppercase">{{ headline }}</h3>
      <span class="accordion__collapse"></span>
    </div>

    <div class="accordion__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',

  data() {
    return {
      open: false
    }
  },

  props: {
    headline: {
      type: String,
      require: true
    },

    active: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.open = this.active
  }
}
</script>
